<template>
  <div v-if="error" class="error-block bg-red-5">
    <h6 class="text-red-10">
      {{ formattedError.title }}
    </h6>
    <h6 v-if="formattedError.message" class="text-red-10">
      {{ formattedError.message }}
    </h6>
    <p
      v-for="e in formattedError.errors"
      :key="e"
      class="text-white form-error"
    >
      {{ e }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['error'],
  computed: {
    formattedError () {
      const errors = this.error.errors || this.error.detail
      return {
        title: this.error.title,
        message: this.error.message,
        errors: Object.keys(errors).map(k => {
          return errors[k][0]
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.error-block
  padding 10px
  border-radius 3px
.form-error
  font-size 14px
</style>
